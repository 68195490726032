import store from '@/store';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';
import { ref, watch } from '@vue/composition-api';

export default function useCalendarList() {
    const calendarListTable = ref([]);

    const calendarTableColumns = [
        { text: i18n.t('StartDate'), value: 'start_date', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('EndDate'), value: 'end_date', class: 'text-uppercase', width: '12rem' },
        { text: i18n.tc('Monday'), value: 'monday', class: 'text-uppercase', width: '15rem' },
        { text: i18n.tc('Tuesday'), value: 'tuesday', class: 'text-uppercase', width: '15rem' },
        { text: i18n.tc('Wednesday'), value: 'wednesday', class: 'text-uppercase', width: '15rem' },
        { text: i18n.tc('Thursday'), value: 'thursday', class: 'text-uppercase', width: '15rem' },
        { text: i18n.tc('Friday'), value: 'friday', class: 'text-uppercase', width: '15rem' },
        { text: i18n.tc('Saturday'), value: 'saturday', class: 'text-uppercase', width: '15rem' },
        { text: i18n.tc('Sunday'), value: 'sunday', class: 'text-uppercase', width: '15rem' },

        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const totalCalendarListTable = calendarListTable.length;
    const oldSearch = ref('');
    const oldStartDateFilter = ref('');
    const oldEndDateFilter = ref('');

    const fetchCalendarList = () => {
        let verifyQuery = store.getters['app-routes/getCalendarsQuery'];
        let isDeleteCalendar = store.getters['app-routes/getIsDeleteCalendar'];
        store
            .dispatch('app-routes/fetchCalendarList', {})
            .then((response) => {
                if (isDeleteCalendar) {
                    calendarListTable.value = [];
                    calendarListTable.value.push(...response.data.data);
                    calendarListTable.value = _.uniqBy(calendarListTable.value, 'id');
                    store.commit('app-routes/setIsDeleteCalendar', false);
                } else if (
                    oldSearch.value == verifyQuery.filters.$or[0].start_date.$containsi &&
                    oldStartDateFilter.value == verifyQuery.filters.start_date.$gte &&
                    oldEndDateFilter.value == verifyQuery.filters.end_date.$lte
                ) {
                    calendarListTable.value.push(...response.data.data);
                    calendarListTable.value = _.uniqBy(calendarListTable.value, 'id');
                } else {
                    oldSearch.value = verifyQuery.filters.$or[0].start_date.$containsi;
                    oldStartDateFilter.value = verifyQuery.filters.start_date.$gte;
                    oldEndDateFilter.value = verifyQuery.filters.end_date.$lte;

                    calendarListTable.value = [];
                    calendarListTable.value.push(...response.data.data);

                    //remove duplicate from array
                    calendarListTable.value = _.uniqBy(calendarListTable.value, 'id');
                }

                totalCalendarListTable.value = response.data.meta.pagination.total;
            })
            .catch((error) => {
                throw error;
            });
    };

    return {
        calendarListTable,
        calendarTableColumns,
        totalCalendarListTable,
        fetchCalendarList,
    };
}
